.items_container {
    margin: 1em 0 2em 0;
}

.item_header_text {
    font-size: 0.6em;
    padding: 1em 0;
    color: #737373;
}

.items {
    display: flex;
}

.item_container {
    height: 10em;
    width: 8em;
    margin: 1em;
}

.item_image {
    height: 100%;
    width: 100%;
    background-color: #373737;
    padding: 0.5em;
    border-radius: 0.5em;
}

.item_title {
    font-size: 1.25em;
    font-weight: 600;
}

.like_container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff2d53;
    
    transition: all 0.3s ease-in-out;
}

.like_container:hover {
    color: #fe5f7c;
}

.image-container {
    position: relative;
}

.heart-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    opacity: 0;
    animation: heart-animation 1s ease-in-out;
}

@keyframes heart-animation {
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0); opacity: 0; }
}

@keyframes grow {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.like_heart {
    display: flex;
    align-items: center;
    height: 1.1em;
    width: 1.1em;
    animation: grow 1s ease-in-out infinite;
}

.like_container svg {
    height: 0.777em;
    width: 1em;
}

.like_container p {
    font-size: 17px;
    color: rgb(255, 98, 127);
}

.item_color {
    font-size: 0.5em;
    font-weight: light;
}

.color_circle {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 1px solid #2c2c2c;
    margin: 0 0.5em;
}
.color-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8em;
}

.item_info {
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #292929;
    font-size: 0.8em;
}

@media screen and (max-width: 800px) {
    .items {
        display: flex;
        flex-direction: column;
    }

    .item_title {
        font-size: 2em;
    }

    .image-container {
        height: 15em;
        width: 15em;
    }

    .item_container {
        height: auto;
        width: 15em;
    }
}