.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    z-index: 1000; /* high z-index to overlay over everything */
}

.mail_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.panel {
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    /* max-width: 500px; */
    margin: auto;
    width: 30em;
}

.panel h2 {
text-align: center;
margin-bottom: 20px;
}

.panel form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel form input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.panel form button[type="submit"] {
    padding: 0 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end; /* align the button to the right */
}

.panel form button[type="submit"]:hover {
background-color: #0056b3;
}

.panel button {
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 17px;
    cursor: pointer;
}

.discription {
    display: flex;
    justify-content: center;
    width: 25em;
    color: #6f6f6f;
    padding: 0 0 1em 0;
}

button {
    padding: 5px 10px;
    background-color: #f4f4f4;
    color: rgb(108, 108, 108);
    border: none;
    cursor: pointer;
    margin-top: 10px;
    height: 2em;
}

button:hover {
    color: rgb(223, 68, 68);
    border-radius: 0.25em;
}

.error-message {
    color: #fff;
    font-weight: bold;
    font-weight: 500;
    margin: 1em;
    padding: 0.5em;
    background-color: rgb(223, 68, 68);
    border-radius: 0.25em;
    border: 1px solid rgb(160, 31, 31); /* Add this line */
    width: 100%;
}

.success-message {
    margin: 0 0 1em 0;
}