* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer_container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_divider_container {
    width: 100%;
    display: flex;  
    justify-content: center;
}

.footer_content {
    padding: 0 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 70em;
    width: 100%;
}

.footer_socials {
    color: #3185FC;
    display: flex;
}

.footer_name {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 0.8rem;
    padding: 0.5em;
    text-align: center;
    font-family: "Pacifico", cursive;
    font-weight: 400;
    font-style: normal;
}
.footer_name:hover {
    cursor: pointer;
}

.footer_logo {
    height: auto;
    width: 2em;
    margin-right:0.5em;
}

.footer_divider {
    height: 1px; /* Adjust the height of the line as needed */
    width: 70%;
    background: linear-gradient(to right, transparent, #797979, transparent); /* Adjust the colors as needed */
    margin: 10px 0; /* Adjust the margin as needed */
    max-width: 50em;
}

/* .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5em;
    height: 2em;
    width: 2em;
}

.social svg {
    height: 100%;
    width: 100%;
} */

.join_mail_text {
    font-weight: 500;
    color: #222222;
    cursor: pointer;
}

.join_mail_text:hover {
    color: #686868;
}

@media screen and (max-width: 600px) {
    .footer_content {
        flex-direction: column;
    }
}