* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3185FC;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3em;
    width: 100%;
    max-width: 70em;
}

.header_content_divider {
    width: 100%;
    display: flex;  
    justify-content: center;
}

.header_name {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.8rem;
    padding: 1rem;
    text-align: center;
    font-family: "Pacifico", cursive;
    font-weight: 400;
    font-style: normal;
}
.header_name:hover {
    cursor: pointer;
}

.header_logo {
    height: auto;
    width: 2em;
    margin-right:0.5em;
}

.header_socials {
    display: flex;
    background-color: rgba(255,255,255,0.34);
    border-radius: 10px;
    padding: 0.2em 0.5em;
}

.header_social {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0.5em; */
    width: 1em;
    height: 1em;
    /* background-color: #fff; */
    border-radius: 100%;
    padding: 1em;
    transition: transform 0.3s ease-in-out;
}
.header_social:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.header_social svg {
    height: 100%;
    width: 100%;
}

.divider {
    height: 1px; /* Adjust the height of the line as needed */
    width: 70%;
    background: linear-gradient(to right, transparent, #000000, transparent); /* Adjust the colors as needed */
    
    max-width: 50em;
}

.comming_soon {
    color: #fff;
    font-weight: 600;
}