/* banner.css */
.banner {
    width: 100%;
    padding: 0.25em 0;
    background-color: #ffd000;
    text-align: center;
}
/* .banner:hover {
    cursor: pointer;
} */

.banner p {
    color: #333;
    font-size: 0.77em;
    font-weight: bold;
}